import { Box, MenuItem, Select, Typography } from "@mui/material";

const SelectInfo = (props) => {
    return (
        <Box
            sx={{
                backgroundColor: "white",
                border: "1px solid #eee",
                borderRadius: "1rem",
                display: "flex",
                alignItems: "center",
                "&:hover": {
                    borderColor: "#eee",
                },
            }}
        >
            <Typography
                sx={{
                    width: "140px",
                    paddingLeft: ".75rem",
                    fontSize: ".875rem",
                    fontWeight: "700",
                    color: "#004C60",
                    ...props.sx,
                }}
            >
                {props.label}:
            </Typography>
            <Select
                id={props.id}
                name={props.name}
                value={props.value || ""}
                onChange={props.onChange}
                onBlur={props.onBlur}
                disabled={props.disabled}
                fullWidth
                sx={{
                    width: "100%",
                    fontSize: ".875rem",
                    color: "#004C60",
                    "& .MuiSelect-select": {
                        padding: ".8rem 0",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                    },
                    "& .MuiSelect-select.Mui-disabled": {
                        WebkitTextFillColor: "#004C60",
                        cursor: "not-allowed",
                    },
                }}
            >
                {props.listItems?.length > 0 ? (
                    props.listItems.map((item) => {
                        return (
                            <MenuItem
                                key={item.id || item.value}
                                value={item.value}
                                sx={{ fontSize: ".875rem", color: "#004C60" }}
                            >
                                {item.value}
                            </MenuItem>
                        );
                    })
                ) : (
                    <MenuItem value="" disabled></MenuItem>
                )}
            </Select>
        </Box>
    );
};

export default SelectInfo;
