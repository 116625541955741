import { useCallback, useEffect, useState } from "react";
import {
    Avatar,
    Badge,
    Box,
    CircularProgress,
    Dialog,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useAppContext } from "../../hooks/useAppContext";
import { baseURL } from "../../api/url";
import { useLineContext } from "../../hooks/useLineContext";
import HistoryTable from "../history/components/HistoryTable";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarRateIcon from "@mui/icons-material/StarRate";
import bgStar from "../../assets/images/bg/bg-star.png";
import { styled } from "@mui/system";
import axios from "axios";
import RedButton from "../../components/ui/RedButton";
import WhiteButton from "../../components/ui/WhiteButton";
import { AddCircleOutline, CloseOutlined } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";
import DialogSuccess from "../../components/ui/DialogSuccess";
import DialogError from "../../components/ui/DialogError";

const TopicTypo = styled(Typography)({
    fontSize: "1rem",
    fontWeight: "500",
    color: "#004B61",
    whiteSpace: "pre-wrap",
});

const ValueTypo = styled(Typography)({
    fontSize: "1rem",
    color: "#004B61",
    whiteSpace: "pre-wrap",
});

const AdminHistoryPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { liffToken } = useLineContext();
    const { companyInfo, setCompanyInfo } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [updateOwnerDialog, setUpdateOwnerDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState({ _id: "", displayName: "", userId: "" });
    const [addNewUserDialog, setAddNewUserDialog] = useState(false);
    const [searchUser, setSearchUser] = useState("");
    const [listUsers, setListUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [size] = useState(10);
    const [hasMoreUsers, setHasMoreUsers] = useState(true);
    const [addMemberSuccessDialog, setAddMemberSuccessDialog] = useState(false);
    const [addMemberErrorDialog, setAddMemberErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleUpdateOwner = (user) => {
        setUpdateOwnerDialog(true);
        setSelectedUser({
            _id: user._id,
            displayName: user.displayName,
            userId: user.userId,
        });
    };

    const handleCloseUpdateOwnerDialog = () => {
        setUpdateOwnerDialog(false);
        setSelectedUser({ _id: "", displayName: "", userId: "" });
    };

    const handleSubmitUpdateOwner = async () => {
        try {
            const response = await axios.post(
                `${baseURL}/update_info_company`,
                { user_id: selectedUser._id },
                {
                    headers: {
                        Authorization: `Bearer ${liffToken}`,
                    },
                    params: {
                        company_id: companyInfo._id,
                    },
                }
            );

            if (response.data.Code === "20100" || response.data.Code === "20000") {
                setUpdateOwnerDialog(false);
                fetchCompany();
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleClickAddNewUser = () => {
        setAddNewUserDialog(true);
        fetchUser(1);
    };

    const handleCloseSuccessDialog = () => {
        setAddMemberSuccessDialog(false);
        fetchCompany();
    };

    const fetchCompany = useCallback(() => {
        if (liffToken) {
            axios
                .get(`${baseURL}/companyinfo`, {
                    headers: {
                        Authorization: `Bearer ${liffToken}`,
                    },
                    params: {
                        code_company: id,
                    },
                })
                .then((res) => {
                    if (res.data.Code === "20000") {
                        setCompanyInfo({
                            ...res.data.Data,
                            accumulated_points: res.data.Data.accumulated_points.toFixed(2),
                            spending_amount: res.data.Data.spending_amount.toFixed(2),
                            spending_criteria: res.data.Data.spending_criteria.toFixed(2),
                        });
                    }

                    setIsLoading(false);
                })
                .catch((e) => {
                    throw new Error("Can't get company info", e);
                });
        }
    }, [liffToken, setCompanyInfo, id]);

    const fetchUser = useCallback(
        (nextPage) => {
            if (liffToken) {
                axios
                    .get(`${baseURL}/list_user`, {
                        headers: { Authorization: `Bearer ${liffToken}` },
                        params: {
                            search: searchUser,
                            page: nextPage,
                            limit: size,
                            sort_col: "_id",
                            sort_value: true,
                        },
                    })
                    .then((res) => {
                        setListUsers((prev) => _.uniqBy([...prev, ...res.data.Data.data], "_id"));
                        setHasMoreUsers(listUsers.length + res.data.Data.data.length < res.data.Data.total);
                        setPage(nextPage);
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            }
        },
        [liffToken, searchUser, size, listUsers]
    );

    const addNewUser = async (user) => {
        const response = await axios.post(
            `${baseURL}/company_add_member`,
            {
                code_company: id,
                user_id: user._id,
            },
            { headers: { Authorization: `Bearer ${liffToken}` } }
        );

        if (response.data.Code !== "20000") {
            setAddMemberErrorDialog(true);
            setErrorMessage(response.data.DeveloperMessage);
        } else {
            setAddMemberSuccessDialog(true);
        }
    };

    const addMember = (user) => {
        addNewUser(user);
    };

    useEffect(() => {
        fetchCompany();
    }, [fetchCompany]);

    useEffect(() => {
        let timerId = setTimeout(() => {
            setListUsers([]);
            fetchUser(1);
            setHasMoreUsers(true);
        }, 500);

        return () => clearTimeout(timerId);
        // eslint-disable-next-line
    }, [searchUser]);

    return (
        <>
            <Helmet>
                <title>ประวัติการใช้งาน | Caltex StarCard</title>
            </Helmet>

            <Box
                component="main"
                mx="auto"
                sx={{
                    background: `url(${bgStar}) no-repeat center center / cover`,
                    backgroundAttachment: "fixed",
                }}
            >
                <Box component="section" maxWidth="lg" mx="auto" sx={{ padding: "6rem 1.25rem" }}>
                    <Typography
                        sx={{
                            fontSize: "1.5rem",
                            fontWeight: "500",
                            color: "#002B38",
                            textAlign: "center",
                            marginBottom: "1.25rem",
                        }}
                    >
                        ประวัติการใช้งาน
                    </Typography>

                    <IconButton
                        onClick={() => navigate('/admin')}
                        sx={{
                            fontSize: "1.25rem",
                            color: "#004B61",
                            marginBottom: "1rem",
                            position: "absolute",
                            top: "80px",
                            left: "16px",
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        }}
                    >
                        <ArrowBackIosIcon
                            sx={{
                                fontSize: "1rem",
                                color: "#004B61",
                            }}
                        />
                        ย้อนกลับ
                    </IconButton>

                    <Box p={2} my={3} sx={{ backgroundColor: "white", borderRadius: "8px" }}>
                        {isLoading && <CircularProgress sx={{ display: "block", margin: "50px auto" }} />}

                        {!isLoading && (
                            <Grid container columnSpacing={2} rowSpacing={1} sx={{ position: "relative" }}>
                                <Box sx={{ position: "absolute", top: "10px", right: "10px" }}>
                                    <EditIcon
                                        sx={{ fontSize: "1.5rem", color: "#002B38", cursor: "pointer" }}
                                        onClick={() => navigate(`/admin/company/${companyInfo?.code}`)}
                                    />
                                </Box>
                                <Grid item xs={5}>
                                    <TopicTypo>รหัสบริษัท:</TopicTypo>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValueTypo>{companyInfo.code}</ValueTypo>
                                </Grid>

                                <Grid item xs={5}>
                                    <TopicTypo>ชื่อบริษัท:</TopicTypo>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValueTypo>{companyInfo.company_name}</ValueTypo>
                                </Grid>

                                <Grid item xs={5}>
                                    <TopicTypo>คะแนนสะสม:</TopicTypo>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValueTypo>{companyInfo.accumulated_points}</ValueTypo>
                                </Grid>

                                <Grid item xs={5}>
                                    <TopicTypo>เกณฑ์การให้คะแนน:</TopicTypo>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValueTypo>{companyInfo.spending_criteria}</ValueTypo>
                                </Grid>

                                <Grid item xs={5}>
                                    <TopicTypo>ยอดที่ใช้ไปล่าสุด:</TopicTypo>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValueTypo>{companyInfo.spending_amount}</ValueTypo>
                                </Grid>

                                <Grid item xs={5}>
                                    <TopicTypo>ชื่อผู้มีอำนาจ:</TopicTypo>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValueTypo>{companyInfo.people_name}</ValueTypo>
                                </Grid>

                                <Grid item xs={5}>
                                    <TopicTypo>รายชื่อผู้ใช้งาน:</TopicTypo>
                                </Grid>
                                <Grid item xs={7}>
                                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                        {companyInfo.user.length > 0
                                            ? companyInfo.user.map((item) => {
                                                  if (item.message === "Not found") {
                                                      return (
                                                          <Box key={item.userId}>
                                                              <ListItem alignItems="center" sx={{ paddingLeft: "0" }}>
                                                                  <ListItemAvatar>
                                                                      <Avatar />
                                                                  </ListItemAvatar>
                                                                  <ListItemText
                                                                      primary={`Unknown ${item.userId}`}
                                                                      secondary={item.phonenumber}
                                                                  />
                                                              </ListItem>
                                                          </Box>
                                                      );
                                                  }

                                                  if (item.owner) {
                                                      return (
                                                          <Box key={item.userId}>
                                                              <ListItem alignItems="center" sx={{ paddingLeft: "0" }}>
                                                                  <ListItemAvatar
                                                                      sx={{ position: "relative", cursor: "pointer" }}
                                                                      onClick={() => handleUpdateOwner(item)}
                                                                  >
                                                                      <Avatar
                                                                          alt={item.displayName}
                                                                          src={item.pictureUrl}
                                                                          sx={{ border: "1px solid #ddd" }}
                                                                      />
                                                                      <Badge
                                                                          color="primary"
                                                                          sx={{
                                                                              position: "absolute",
                                                                              top: "-10px",
                                                                              right: "10px",
                                                                          }}
                                                                      >
                                                                          <StarRateIcon sx={{ color: "#FFC93C" }} />
                                                                      </Badge>
                                                                  </ListItemAvatar>
                                                                  <ListItemText
                                                                      primary={
                                                                          <Box
                                                                              component="span"
                                                                              sx={{ cursor: "pointer" }}
                                                                              onClick={() => handleUpdateOwner(item)}
                                                                          >
                                                                              {item.displayName}
                                                                          </Box>
                                                                      }
                                                                      secondary={item.phonenumber || ""}
                                                                  />
                                                                  <ListItemText />
                                                              </ListItem>
                                                          </Box>
                                                      );
                                                  }

                                                  return (
                                                      <Box key={item.userId}>
                                                          <ListItem alignItems="center" sx={{ paddingLeft: "0" }}>
                                                              <ListItemAvatar
                                                                  sx={{ cursor: "pointer" }}
                                                                  onClick={() => handleUpdateOwner(item)}
                                                              >
                                                                  <Avatar
                                                                      alt={item.displayName}
                                                                      src={item.pictureUrl}
                                                                  />
                                                              </ListItemAvatar>
                                                              <ListItemText
                                                                  primary={
                                                                      <Box
                                                                          sx={{ cursor: "pointer" }}
                                                                          onClick={() => handleUpdateOwner(item)}
                                                                      >
                                                                          {item.displayName}
                                                                      </Box>
                                                                  }
                                                                  secondary={item.phonenumber}
                                                              />
                                                          </ListItem>
                                                      </Box>
                                                  );
                                              })
                                            : null}
                                    </List>

                                    <IconButton
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                            borderRadius: "8px",
                                            "&:hover": {},
                                        }}
                                        onClick={handleClickAddNewUser}
                                    >
                                        <AddCircleOutline sx={{ fontSize: "2.25rem", color: "black" }} />
                                        <Typography sx={{ fontSize: "1rem", color: "black" }}>เพิ่มสมาชิก</Typography>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}
                    </Box>

                    <HistoryTable userInfo={companyInfo} />

                    {/* Update Owner Dialog */}
                    <Dialog open={updateOwnerDialog} disableEscapeKeyDown>
                        <Paper sx={{ minWidth: "300px", backgroundColor: "white", padding: "2rem" }}>
                            <Typography sx={{ fontSize: "18px", textAlign: "center", marginBottom: "20px" }}>
                                ต้องการย้ายสิทธิ์การแลกรางวัลให้กับ{" "}
                                <Box component="span" sx={{ fontWeight: "bold", color: "#FD0004" }}>
                                    {selectedUser.displayName || selectedUser.userId}
                                </Box>{" "}
                                หรือไม่?
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                                <WhiteButton onClick={handleCloseUpdateOwnerDialog} sx={{ width: "100px" }}>
                                    ยกเลิก
                                </WhiteButton>
                                <RedButton onClick={handleSubmitUpdateOwner} sx={{ width: "100px" }}>
                                    ตกลง
                                </RedButton>
                            </Box>
                        </Paper>
                    </Dialog>

                    {/* Add new user Dialog */}
                    <Dialog open={addNewUserDialog} onClose={() => setAddNewUserDialog(false)}>
                        <Paper
                            sx={{ minWidth: "500px", backgroundColor: "white", padding: "2rem", position: "relative" }}
                        >
                            <IconButton
                                onClick={() => setAddNewUserDialog(false)}
                                sx={{ position: "absolute", top: "0", right: "0" }}
                            >
                                <CloseOutlined />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontSize: "1.5rem",
                                    fontWeight: "500",
                                    color: "#002B38",
                                    textAlign: "center",
                                    marginBottom: "20px",
                                }}
                            >
                                ต้องการเพิ่มสมาชิก
                            </Typography>
                            <TextField
                                id="addNewUser"
                                placeholder="ค้นหาสมาชิกจาก ชื่อไลน์หรือเบอร์โทร"
                                size="small"
                                hiddenLabel
                                fullWidth
                                value={searchUser}
                                onChange={(e) => setSearchUser(e.target.value || "")}
                            />
                            <Box id="target" sx={{ height: "400px", overflow: "auto", padding: "15px 0" }}>
                                <InfiniteScroll
                                    dataLength={listUsers.length}
                                    next={() => fetchUser(page + 1)}
                                    hasMore={hasMoreUsers}
                                    loader={<Box sx={{ fontSize: "16px", textAlign: "center" }}>Loading...</Box>}
                                    scrollableTarget="target"
                                >
                                    {listUsers.map((item) => (
                                        <ListItem key={item.lineuserid} alignItems="center" sx={{ paddingLeft: "0" }}>
                                            <ListItemAvatar>
                                                <Avatar src={item.pictureUrl} />
                                            </ListItemAvatar>
                                            <ListItemText primary={item.displayName || item.lineuserid} />
                                            <RedButton onClick={() => addMember(item)} sx={{ height: "35px" }}>
                                                เพิ่ม
                                            </RedButton>
                                        </ListItem>
                                    ))}

                                    {listUsers.length <= 0 && (
                                        <Box sx={{ fontSize: "18px", textAlign: "center" }}>ไม่พบรายชื่อ</Box>
                                    )}
                                </InfiniteScroll>
                            </Box>
                        </Paper>
                    </Dialog>

                    <DialogSuccess
                        isOpen={addMemberSuccessDialog}
                        onClose={handleCloseSuccessDialog}
                        text="เพิ่มสมาชิกสำเร็จ"
                        btnText="ปิด"
                    />

                    <DialogError
                        isOpen={addMemberErrorDialog}
                        onClose={() => setAddMemberErrorDialog(false)}
                        text={errorMessage}
                        btnText="ปิด"
                    />
                </Box>
            </Box>
        </>
    );
};

export default AdminHistoryPage;
