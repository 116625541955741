import api from '../libs/axios'

export const updateCompany = async (dto) => {
    try {
        const response = await api.post('/update_info_company', dto, {
            params: { company_id: dto.company_id }
        })

        return response.data;
    } catch (err) {
        console.error(err)
    }

}