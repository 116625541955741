import { TextField, Typography } from "@mui/material";

const InputInfo = (props) => {
    return (
        <TextField
            fullWidth
            variant="outlined"
            autoComplete="off"
            InputProps={{
                startAdornment: (
                    <Typography
                        sx={{ width: "120px", fontSize: ".875rem", fontWeight: "700", color: "#004C60" }}
                    >
                        {props.text}:
                    </Typography>
                ),
            }}
            sx={{
                "& .MuiInputBase-root": {
                    fontSize: ".875rem",
                    color: "#004C60",
                    backgroundColor: "white",
                    borderRadius: "1rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#EEEEEE !important",
                },
                "& .MuiInputBase-input": {
                    padding: "15px 5px",
                },
                "& .MuiInputBase-input.Mui-disabled": {
                    color: "#004C60",
                    WebkitTextFillColor: "#004C60",
                    cursor: "not-allowed",
                },
            }}
            {...props}
        />
    );
};

export default InputInfo;
