import { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { useAppContext } from "../../hooks/useAppContext";
import { Box, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { updateCompany } from "../../api/company";
import { userStattus } from "../../constants/userStatus";
import InputInfo from "../../components/ui/inputs/InputInfo";
import * as Yup from "yup";
import bgStar from "../../assets/images/bg/bg-star.png";
import _ from "lodash";
import ErrorMessage from "../../components/ui/ErrorMessage";
import InputPhoneNumber from "../../components/ui/inputs/InputPhoneNumber";
import SelectInfo from "../../components/ui/selects/SelectInfo";
import RedButton from "../../components/ui/RedButton";
import WhiteButton from "../../components/ui/WhiteButton";
import DialogLoading from "../../components/ui/DialogLoading";
import DialogSuccess from "../../components/ui/DialogSuccess";
import DialogError from "../../components/ui/DialogError";

const EditCompanyPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { companyInfo } = useAppContext();
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [successDialog, setSuccessDialog] = useState(false);
    const [errorsDialog, setErrorDialog] = useState(false);

    const defaultValues = useMemo(() => {
        if (!_.isEmpty(companyInfo)) {
            return {
                companyName: companyInfo.company_name,
                fname: companyInfo.people_name
                    .trim()
                    .split(" ")
                    .filter((item) => item)[0],
                lname: companyInfo.people_name
                    .trim()
                    .split(" ")
                    .filter((item) => item)[1],
                phoneNumber: companyInfo.phonenumber,
                state: companyInfo.state,
            };
        } else {
            return {};
        }
    }, [companyInfo]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: defaultValues,
        validationSchema: Yup.object({
            companyName: Yup.string().required("โปรดระบุชื่อบริษัทให้ถูกต้อง"),
            fname: Yup.string().required("โปรดระบุชื่อให้ถูกต้อง"),
            lname: Yup.string().required("โปรดระบุนามสกุลให้ถูกต้อง"),
            phoneNumber: Yup.string()
                .min(10, "โปรดระบุเบอร์โทรศัพท์อย่างน้อย 10 หลัก")
                .required("โปรดระบุเบอร์โทรศัพท์ให้ถูกต้อง"),
            state: Yup.string().required("โปรดระบุสถานะให้ถูกต้อง"),
        }),
        onSubmit: async (values) => {
            try {
                setLoadingDialog(true);

                const dto = {
                    company_id: companyInfo._id,
                    company_name: values.companyName,
                    people_name: `${values.fname} ${values.lname}`,
                    phonenumber: values.phoneNumber,
                    state: values.state,
                };

                const response = await updateCompany(dto);

                if (response.Code === "20000" || response.Code === "20100") {
                    setSuccessDialog(true);
                } else {
                    setErrorDialog(true);
                }
            } catch (err) {
                console.error(err);
            } finally {
                setLoadingDialog(false);
            }
        },
    });

    const onCloseSuccessDialog = () => {
        setSuccessDialog(false);
        navigate(`/admin/${id}`);
    };

    useEffect(() => {
        if (Object.keys(companyInfo).length <= 0) {
            navigate(`/admin/${id}`);
        }
    }, [companyInfo, id, navigate]);

    return (
        <Box
            sx={{
                width: "100%",
                height: "100vh",
                padding: "6rem 2rem 40px",
                background: `url(${bgStar}) no-repeat center center / cover`,
                overflow: "auto",
                position: "relative",
            }}
        >
            <Typography
                sx={{
                    fontSize: "1.5rem",
                    fontWeight: "500",
                    color: "#002B38",
                    textAlign: "center",
                    marginBottom: "1.25rem",
                }}
            >
                แก้ไขข้อมูลร้านค้า
            </Typography>
            <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{ maxWidth: "768px", margin: "0 auto", display: "flex", flexDirection: "column", gap: "10px" }}
            >
                <Box>
                    <InputInfo
                        id="companyName"
                        name="companyName"
                        text="ชื่อร้านค้า"
                        placeholder="ชื่อร้านค้า"
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.companyName && formik.errors.companyName && (
                        <ErrorMessage>{formik.errors.companyName}</ErrorMessage>
                    )}
                </Box>
                <Box>
                    <InputInfo
                        id="fname"
                        name="fname"
                        text="ชื่อ"
                        placeholder="ชื่อ"
                        value={formik.values.fname}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.fname && formik.errors.fname && <ErrorMessage>{formik.errors.fname}</ErrorMessage>}
                </Box>
                <Box>
                    <InputInfo
                        id="lname"
                        name="lname"
                        text="นามสกุล"
                        placeholder="นามสกุล"
                        value={formik.values.lname}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.lname && formik.errors.lname && <ErrorMessage>{formik.errors.lname}</ErrorMessage>}
                </Box>
                <Box>
                    <InputPhoneNumber
                        id="phoneNumber"
                        name="phoneNumber"
                        text="เบอร์โทรศัพท์"
                        placeholder="เบอร์โทรศัพท์"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                        <ErrorMessage>{formik.errors.phoneNumber}</ErrorMessage>
                    )}
                </Box>
                <Box>
                    <SelectInfo
                        id="state"
                        name="state"
                        label="สถานะ"
                        listItems={userStattus}
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.state && formik.errors.state && <ErrorMessage>{formik.errors.state}</ErrorMessage>}
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "20px 0" }}>
                    <WhiteButton type="submit" sx={{ width: "150px" }} onClick={() => navigate(`/admin/${id}`)}>
                        ย้อนกลับ
                    </WhiteButton>
                    <RedButton type="submit" sx={{ width: "150px" }}>
                        บันทึก
                    </RedButton>
                </Box>
            </Box>

            <DialogLoading open={loadingDialog} message="กำลังอัพเดตข้อมูลร้านค้า" />
            <DialogSuccess isOpen={successDialog} onClose={onCloseSuccessDialog} text="อัพเดตข้อมูลร้านค้าสำเร็จ" btnText="ปิด"/>
            <DialogError
                isOpen={errorsDialog}
                onClose={() => setErrorDialog(false)}
                text="แก้ไขข้อมูลไม่สำเร็จ โปรดลองใหม่อีกครั้ง"
            />
        </Box>
    );
};

export default EditCompanyPage;
