import axios from "axios";
import liff from "@line/liff";
import { baseURL } from "../api/url";

const api = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT || baseURL || "http://localhost:3000",
    headers: {
        "Content-Type": "application/json",
    },
});

api.interceptors.request.use(
    async (config) => {
        try {
            await liff.ready;
            const token = liff.getAccessToken();

            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        } catch (error) {
            console.error("Error retrieving LIFF token:", error);
        }

        return config;
    },
    (error) => Promise.reject(error)
);

export default api;
