export const userStattus = [
    {
        id: 1,
        value: 'ACCOUNT READY'
    },
    {
        id: 2,
        value: 'ACCOUNT SUSPEND'
    },
    {
        id: 3,
        value: 'ACCOUNT TERMINATE'
    }
]