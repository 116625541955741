import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";
import { useLineContext } from "../../hooks/useLineContext";
import { baseURL } from "../../api/url";
import RegisterPhone from "./components/RegisterPhone";
import RegisterOTP from "./components/RegisterOTP";
import RegisterForm from "./components/RegisterForm";
import DialogInfo from "../../components/ui/DialogInfo";
import mainBg from "../../assets/images/bg/main-bg.png";
import secondBg from "../../assets/images/bg/second-bg.png";
import mainLogo from "../../assets/images/logo/main-logo.png";
import axios from "axios";
import Footer from "../../layouts/Footer";
import GiftBox from "../../assets/icon/giftbox.svg";

const RegisterPage = () => {
    const { liffToken } = useLineContext();
    const [activeStep, setActiveStep] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [renderText, setRenderText] = useState(true);

    const sentRequestOTP = async (phoneNumber) => {
        try {
            const response = await axios.post(
                `${baseURL}/request_otp`,
                { phonenumber: phoneNumber },
                { headers: { Authorization: `Bearer ${liffToken}` } }
            );

            if (response.data.Code === "20000") {
                setActiveStep(2);
            } else {
                setIsOpen(true);
            }
        } catch (e) {
            console.error("Can't send request OTP", e);
        }
    };

    const onCloseDialog = () => setIsOpen(false);

    const renderSteps = () => {
        switch (activeStep) {
            case 2:
                return (
                    <RegisterOTP
                        phoneNumber={phoneNumber}
                        setActiveStep={setActiveStep}
                        sentRequestOTP={sentRequestOTP}
                    />
                );
            case 3:
                return <RegisterForm phoneNumber={phoneNumber} activeStep={activeStep} setActiveStep={setActiveStep} />;
            case 1:
            default:
                return (
                    <>
                        <RegisterPhone
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            sentRequestOTP={sentRequestOTP}
                        />
                        <DialogInfo
                            isOpen={isOpen}
                            onClose={onCloseDialog}
                            text={"ไม่สามารถขอ OTP ได้ในขณะนี้ \nโปรดลองใหม่อีกครั้ง"}
                            btnText="ปิด"
                        />
                    </>
                );
        }
    };

    const backgroundCondition =
        activeStep <= 2
            ? `url(${mainBg}) no-repeat center center / cover, #365CA0`
            : `url(${secondBg}) no-repeat center center / cover, white`;

    useEffect(() => {
        if (activeStep <= 2) {
            setRenderText(true);
        } else {
            setRenderText(false);
        }
    }, [activeStep]);

    return (
        <>
            <Helmet>
                <title>Register | Caltex StarCard</title>
            </Helmet>

            <Box component="main" maxWidth="md" mx="auto" sx={{ background: `${backgroundCondition}` }}>
                <Box component="section" sx={{ padding: "0" }}>
                    <Box
                        sx={{
                            height: activeStep > 2 ? "100vh" : "calc(100vh - 100px)",
                            minHeight: "500px",
                            overflow: "auto",
                        }}
                    >
                        <Box
                            sx={{
                                background:
                                    activeStep > 2
                                        ? `url(${mainBg}) no-repeat 110% 30% / 120%, #00566c`
                                        : "transparent",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "1.5rem",
                            }}
                        >
                            <Box
                                component="img"
                                src={mainLogo}
                                alt="Caltex Starcard Club Logo"
                                sx={{
                                    height: "70px",
                                    display: "block",
                                }}
                            />
                        </Box>
                        {renderSteps()}
                    </Box>
                    {activeStep <= 2 && (
                        <Footer
                            renderText={renderText}
                            icon={GiftBox}
                            iconStyle={{ width: "60px", height: "60px", marginTop: "27px" }}
                        />
                    )}
                </Box>
            </Box>
        </>
    );
};

export default RegisterPage;
